import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

export interface marketplaceState {
  id: string | null;
}

const initialState: marketplaceState = {
  id: null,
};

const marketplace = createSlice({
  name: 'post',
  initialState,
  reducers: {
    setmarketplaceId(state, action: PayloadAction<string>) {
      state.id = action.payload;
    },
  },
});

export const selectPostId = (state: RootState) => state.marketplace.id;

export default marketplace;
