import { Flex, Wrap } from '@chakra-ui/react';
import * as React from 'react';
import OpaqueLayer from './OpaqueLayer';
interface PopupBaseProps {
  visible: boolean;
  children: React.ReactNode;
}

const { useState, useEffect } = React;

const PopupBase: React.FC<PopupBaseProps> = ({ visible, children }) => {
  const [closed, setClosed] = useState(true);
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | null = null;
    if (visible) {
      setClosed(false);
    } else {
      timeoutId = setTimeout(() => {
        setClosed(true);
      }, 200);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [visible]);

  if (!visible && closed) return null;

  return (
    <>
      <OpaqueLayer visible={visible} />
      <Flex
        position="fixed"
        top="0"
        left="0"
        w="100%"
        h="100&"
        zIndex="60"
        alignItems="center"
        justifyContent="center"
      >
        <Wrap
          w="25rem"
          borderRadius="4px"
          p="2rem 1.5rem"
          boxShadow="0 2px 12px 0 rgba(0, 0, 0, 0.09)"
        >
          {children}
        </Wrap>
      </Flex>
    </>
  );
};

export default PopupBase;
