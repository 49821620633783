import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface HeaderState {
  custom: boolean;
  username: string | null;
}

const initialState: HeaderState = {
  custom: false,
  username: null,
};

const header = createSlice({
  name: 'header',
  initialState,
  reducers: {
    enterUsersoulx(
      state,
      action: PayloadAction<{
        username: string;
      }>,
    ) {
      state.custom = true;
      state.username = action.payload.username;
    },
    leaveUserXROOM(state) {
      state.custom = false;
      state.username = null;
    },
  },
});

export default header;
