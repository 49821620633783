import styled from 'styled-components';
import { ReactElement, ReactNode } from 'react';

// Types for props
type IHorizontalCard = {
  children: ReactElement<ICardDesc> | Array<ReactElement<ICardDesc>>;
  className?: string;
};

type IImgBox = {
  imgName: string;
};

type IProps = {
  children: ReactNode;
};

type ICardDesc = {
  sub: string;
  color?: string;
  children: ReactElement<IProps> | Array<ReactElement<IProps>>;
};

// Styled components
const ImgBoxStyled = styled.div`
  width: 100%;
  max-width: 620px;
  height: 437px;
  border-radius: 20px;
  box-shadow: 2px 2px 20px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
`;

const TitleStyled = styled.h2`
  color: #fff;
  font-size: 48px;
  font-weight: 700;
  line-height: normal;


  @media (max-width: 1024px) {
    font-size: 24px;
  }

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const DetailsStyled = styled.p`
  color: #f9f9f9;
  font-size: 20px;
  font-weight: 400;
  line-height: 160%;
  margin-top: 12px;

  @media (max-width: 1024px) {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
const CardDescStyled = styled.div`
  width: 555px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 768px) { /* Mobile */
  padding: 0 40px; /* 좌우에 16px 패딩 추가 */
  }

.sub-text {
  color: #C377FF;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;

  text-align: left;  // Ensure text is aligned to the left
  
  @media (max-width: 1024px) { /* Tablet */
    font-size: 15px;

  }

  @media (max-width: 768px) { /* Mobile */
    font-size: 14px;

  }
}
`;


const HorizontalCardStyled = styled.div<{ className?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-top: 72px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

// ImgBox component
const ImgBox: React.FC<IImgBox> = ({ imgName }) => {
  const imgSrc = `images/xroom_ces/${imgName}.png`;
  const cursorSrc = 'images/xroom_ces/contents_cursor2.svg';

  return (
    <ImgBoxStyled>
      <img src={imgSrc} alt={`${imgName} image`} />
      {imgName === 'content_interface' && (
        <img className="cursor" src={cursorSrc} alt="Cursor over content interface" />
      )}
    </ImgBoxStyled>
  );
};

// Title component
const Title: React.FC<IProps> = ({ children }) => {
  return <TitleStyled>{children}</TitleStyled>;
};

// Details component
const Details: React.FC<IProps> = ({ children }) => {
  return <DetailsStyled>{children}</DetailsStyled>;
};

// CardDesc component
const CardDesc: React.FC<ICardDesc> = ({sub, children }) => {
  return (
    <CardDescStyled>
      <span className="sub-text">{sub}</span>
      <div>{children}</div>
    </CardDescStyled>
  );
};

// HorizontalCard component
const HorizontalCard: React.FC<IHorizontalCard> & {
  Img: React.FC<IImgBox>;
  Title: React.FC<IProps>;
  Details: React.FC<IProps>;
  Desc: React.FC<ICardDesc>;
} = ({ children, className }) => {
  return <HorizontalCardStyled className={className}>{children}</HorizontalCardStyled>;
};

// Assigning child components
HorizontalCard.Title = Title;
HorizontalCard.Details = Details;
HorizontalCard.Desc = CardDesc;
HorizontalCard.Img = ImgBox;

export default HorizontalCard;
