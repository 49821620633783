import {
  Img,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import * as React from 'react';
import { SoulxLogo, XROOMLogo } from 'static/images/Index';

const { useState, useEffect } = React;

interface AuthModalProps {
  visible: boolean;
  children: React.ReactNode;
  onClose: () => void;
}

const AuthModal: React.FC<AuthModalProps> = ({
  visible,
  children,
  onClose,
}) => {
  const [closed, setClosed] = useState(true);
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | null = null;
    if (visible) {
      setClosed(false);
    } else {
      timeoutId = setTimeout(() => {
        setClosed(true);
      }, 200);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [visible]);

  if (!visible && closed) return null;

  return (
    <Modal
      closeOnEsc
      closeOnOverlayClick={false}
      size="sm"
      isCentered
      isOpen={visible}
      onClose={onClose}
      motionPreset="scale"
    >
      <ModalOverlay bg="#000000">
        <ModalContent backgroundColor={'#202020'} h="600px" w="550px">
          <ModalHeader alignSelf="center">
            <Img src={XROOMLogo} alt="" />
          </ModalHeader>
          <ModalCloseButton tabIndex={1} color={'white'} />
          <ModalBody alignSelf="center" textAlign="center">
            {children}
          </ModalBody>
          <ModalFooter alignSelf="center">
            <Img src={SoulxLogo} alt="" />
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default AuthModal;
