import { Flex } from '@chakra-ui/react';
import * as React from 'react';
import { MdCheck } from 'react-icons/md';

export interface AuthEmailSuccessProps {
  registered: boolean;
}

const AuthEmailSuccess: React.FC<AuthEmailSuccessProps> = ({ registered }) => {
  const text = registered ? '로그인' : '회원가입';
  return (
    <Flex>
      <MdCheck className="icon" />
      <div className="description">{text} 링크가 이메일로 전송되었습니다.</div>
    </Flex>
  );
};

export default AuthEmailSuccess;
