import apiClient from './apiClient';
import snakeCaseKeys from 'snakecase-keys';
import axios from 'axios';

/**
 * Send Auth Email
 * docs: https://documenter.getpostman.com/view/723994/S11RJuhq#7933badc-b964-4b84-88ff-4119134925a8
 * @param email
 */
// export const sendAuthEmail = (email: string) =>
//   apiClient.post<SendAuthEmailResponse>('/user/send_register', {
//     email,
//   });
export const sendAuthEmail = (email: string) =>
  axios.post<SendAuthEmailResponse>('/user/send_register', {
    email: email,
  });
export type SendAuthEmailResponse = { registered: any };

/**
 * Get Register Token using code
 * docs: https://documenter.getpostman.com/view/723994/S11RJuhq#ceb55dc0-68f7-4b74-9d30-4e8ce86da988
 * @param code
 */
export const getRegisterToken = (code: string) =>
  axios.get<GetRegisterTokenResponse>(`/user/register/${code}`);

export type GetRegisterTokenResponse = {
  email: string;
  register_token: string;
};

export const localEmailRegister = ({
  registerToken,
  form,
}: {
  registerToken: string;
  form: RegisterFormData;
}) =>
  apiClient.post<AuthResponse>(
    '/user/register/register/local',
    snakeCaseKeys({ registerToken, form }),
  );
type RegisterFormData = {
  nickname: string;
  username: string;
};
export type AuthResponse = {
  email: string;
  is_certified: boolean;
  username: string;
  id: string;
  created_at: string;
  updated_at: string;
  profile: {
    fk_user_id: string;
    display_name: string;
    short_bio: string;
    thumbnail: null;
    id: string;
    created_at: string;
    updated_at: string;
    profile_links: any;
  };
  tokens: {
    access_token: string;
    refresh_token: string;
  };
};

export type LoginResponse = {
data:string;
statusCode:number;
}

/**
 * Login using email code
 * docs: https://documenter.getpostman.com/view/723994/S11RJuhq#ceb55dc0-68f7-4b74-9d30-4e8ce86da988
 * @param code
 */
export const emailCodeLogin = (code: string) =>
  apiClient.get<AuthResponse>(`/api/v2/auth/code/${code}`);

export const logout = () => apiClient.post<void>('/api/v2/auth/logout');

export interface SocialProfile {
  uid: number;
  email: string;
  name: string;
  thumbnail: string;
  username: string;
}

export const getSocialProfile = async () => {
  const response = await apiClient.get<SocialProfile>(
    '/api/v2/auth/social/profile',
  );
  return response.data;
};

export const socialRegister = async (form: RegisterFormData) => {
  const response = await apiClient.post<AuthResponse>(
    '/api/v2/auth/social/register',
    {
      nickname: form.nickname,
      username: form.username,
    },
  );
  return response.data;
};

export const socialLogin = async (params:string) => {
  const response = await apiClient.post<LoginResponse>(
    '/auth/login',
    {
      firebaseUid:params,
      macAddress: ""
    },
  );
  
  return response.data;
};
