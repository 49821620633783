import {
  Box,
  Button,
  Container,
  Image,
  Stack,
  useBreakpointValue,
} from '@chakra-ui/react';
import React from 'react';
import { themedPalette } from '../../lib/styles/themes';

export type ErrorScreenTemplateProps = {
  message: string;
  buttonText?: string;
  onButtonClick?: () => void;
};

function ErrorScreenTemplate({
  message,
  buttonText,
  onButtonClick,
}: ErrorScreenTemplateProps) {
  return (
    <Container display="flex">
      <Box
        w="100%"
        h="100%"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Stack
          className="message"
          pl="1rem"
          pr="1rem"
          whiteSpace="pre"
          textAlign="center"
          lineHeight="1.5"
          fontSize="2.5rem"
          color={themedPalette.text1}
          mt="2rem"
          bg={useBreakpointValue({
            sm: { fontsize: '1.5rem', margintop: '1rem' },
          })}
        >
          {message}
        </Stack>
        {buttonText && (
          <Stack mt="2rem" className="button-wrapper">
            <Button size="lg" onClick={onButtonClick}>
              {buttonText}
            </Button>
          </Stack>
        )}
      </Box>
    </Container>
  );
}

export default ErrorScreenTemplate;
