// translations.ts
export type Language = 'EN' | 'KO';

export const translations: Record<Language, {
  featureSub: string;
  featureTitle: string;
  featureDetails: string;
  servicesub: string;
  serviceDesc: string;
  tryDemo: string;
  assetsSub: string;
  assetsTitle: string;
  assetsDetails: string;
  livestreamSub: string;
  livestreamTitle: string;
  livestreamDetails: string;
  interfaceSub: string;
  interfaceTitle: string;
  interfaceDetails: string;
  lastVisualTitle: string;
  lastVisualDesc: string;
  contactUs: string;
}> = {
  EN: {
    featureSub: "Amazing XR experience",
    featureTitle: "An easy and accessible XR production for everyone.",
    featureDetails: "XROOM is a revolutionary XR production solution that makes magical storytelling possible for anyone. Amateur creators, even teenagers, can create high-quality video content as easily as playing a game, with just a few hours of learning.",
    servicesub: "Easy XROOM Installation Guide",
    serviceDesc: "XROOM installs quickly and easily. Download it on Steam now and experience it for yourself!",
    tryDemo: "Download the trial version",
    assetsSub: "3D Objects & Effects for Dynamic Storytelling!",
    assetsTitle: "Infinite 3D assets for any purpose",
    assetsDetails: "Expand your creativity and imagination with a variety of 3D assets and effects to create original content. Freely organize and edit spaces in real time to tell your story effectively, with any purpose, whether it's to educate, advertise, or perform.",
    livestreamSub: "Live streaming made easy, anytime",
    livestreamTitle: "Share your content live at the touch of a button",
    livestreamDetails: "XRoom works with video streaming platforms like YouTube to make it easy to share your screen in real time. Enjoy efficient live streaming without complicated setup.",
    interfaceSub: "XR and AI technology, what's next…?",
    interfaceTitle: "A flexible and scalable tool with endless possibilities!",
    interfaceDetails: "XROOM is a highly scalable creation tool that enables rapid adoption of the latest technologies. It applies newly updated technologies in the most accessible form, making it intuitive for anyone to utilize them.",
    lastVisualTitle: "Are you ready to explore XROOM?",
    lastVisualDesc: "Bring your own world to life with XR content creation. Become the freest creator of the Metaverse era.",
    contactUs: "CONTACT US",
  },
  KO: {
    featureSub: "놀라운 XR 경험",
    featureTitle: "모두를 위한 쉽고 접근 가능한 XR 제작.",
    featureDetails: "XROOM은 누구나 마법 같은 스토리텔링을 가능하게 하는 혁신적인 XR 제작 솔루션입니다. 아마추어 제작자, 심지어 청소년도 몇 시간의 학습으로 게임을 하듯이 고품질 비디오 콘텐츠를 쉽게 만들 수 있습니다.",
    servicesub: "손쉬운 XROOM 설치 방법",
    serviceDesc: "XROOM은 빠르고 간편하게 설치할 수 있습니다. 스팀에서 지금 바로 다운로드하고, 직접 경험해 보세요!",
    tryDemo: "체험판 다운로드하러가기",
    assetsSub: "역동적인 스토리텔링을 위한 3D 오브젝트와 이펙트들",
    assetsTitle: "모든 용도에 맞춘 무궁무진한 3D 자산",
    assetsDetails: "다양한 3D 자산과 효과로 창의력과 상상력을 확장하여 독창적인 콘텐츠를 만드세요. 교육, 광고 또는 공연 등 어떤 목적이든 이야기를 효과적으로 전달하기 위해 실시간으로 공간을 자유롭게 구성하고 편집할 수 있습니다.",
    livestreamSub: "언제든지 쉽게 라이브 스트리밍",
    livestreamTitle: "“단 한 번의 클릭”으로 콘텐츠를 실시간으로 공유",
    livestreamDetails: "XRoom은 YouTube와 같은 비디오 스트리밍 플랫폼과 연동되어 실시간으로 화면을 쉽게 공유할 수 있습니다. 복잡한 설정 없이 효율적인 라이브 스트리밍을 즐기세요.",
    interfaceSub: "XR 및 AI 기술, 다음은 무엇일까요…?",
    interfaceTitle: "끝없는 가능성을 가진 유연하고 확장 가능한 도구",
    interfaceDetails: "XROOM은 최신 기술을 빠르게 적용할 수 있는 높은 확장성을 갖춘 창작 도구입니다. 새롭게 업데이트된 기술을 가장 접근하기 쉬운 형태로 제공하여 누구나 직관적으로 활용할 수 있도록 합니다.",
    lastVisualTitle: "XROOM을 탐험할 준비가 되셨나요?",
    lastVisualDesc: "XR 콘텐츠 제작으로 당신만의 세계를 생생하게 구현하세요. 메타버스 시대의 가장 자유로운 창작자가 되어 보세요.",
    contactUs: "CONTACT US",
  },
};
