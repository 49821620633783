import * as React from 'react';
import PopupBase from './PopupBase';
import { Box, Button, Text } from '@chakra-ui/react';

export interface PopupOKCancelProps {
  visible: boolean;
  title?: string;
  onConfirm?: () => any;
  onCancel?: () => any;
  children: React.ReactNode;
}

const PopupOKCancel: React.FC<PopupOKCancelProps> = ({
  visible,
  title,
  children,
  onConfirm,
  onCancel,
}) => {
  return (
    <PopupBase visible={visible}>
      <Box>
        {title && (
          <Text fontSize="1.5rem" fontWeight="bold">
            {title}
          </Text>
        )}
        <Box fontSize="1.5rem" mt="1rem" mb="1rem">
          {children}
        </Box>
        <Box className="button-area" mt="2rem">
          {onCancel && (
            <Button color="transparent" onClick={onCancel}>
              취소
            </Button>
          )}
          <Button onClick={onConfirm}>확인</Button>
        </Box>
      </Box>
    </PopupBase>
  );
};

export default PopupOKCancel;
