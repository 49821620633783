import React from 'react';
import { Helmet } from 'react-helmet-async';
import LoginSuccesContainer from 'containers/auth/LoginSuccesContainer';
import CESContainer from 'containers/etc/CESContainer';
export type DownLoadPageProps = {};

function CESPage(props: DownLoadPageProps) {
  return (
    <>
      <Helmet>
        <title>CESPage</title>
        <meta name="robots" content="noindex" />
      </Helmet>
        <main>
        <CESContainer/>
        </main>


    </>
  );
}

export default CESPage;
