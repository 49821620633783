import { combineReducers } from 'redux';
import core, { CoreState } from './core';
import darkMode, { DarkModeState } from './darkmode';
import error, { ErrorState } from './error';
import header, { HeaderState } from './header';
import marketplace, { marketplaceState } from './marketplace';
import XROOM, { XROOMState } from './XROOM';

export type RootState = {
  core: CoreState;
  darkMode: DarkModeState;
  XROOM: XROOMState;
  marketplace: marketplaceState;
  header: HeaderState;
  error: ErrorState;
};
const rootReducer = combineReducers({
  core,
  darkMode: darkMode.reducer,
  marketplace: marketplace.reducer,
  XROOM: XROOM.reducer,
  header: header.reducer,
  error: error.reducer,
});

export default rootReducer;
