import { useEffect, useState } from 'react';
import XroomSXSWDesktop from 'components/soulx/xroomCES/XroomSXSWDesktop';
//import '../../lib/styles/xroom_ces.css'; // Import your styles here

interface XroomSXSWProps {
  language: string;
}

const XroomSXSW: React.FC<XroomSXSWProps> = ({ language }) => {
  const [isChrome, setIsChrome] = useState<boolean>(false);

  useEffect(() => {
    const agent = window.navigator.userAgent.toLowerCase();
    setIsChrome(agent.includes('chrome'));
  }, []);

  // 디버깅을 위해 language 값을 출력합니다.
  console.log('XroomSXSW Component language:', language);

  return (
    <main className={`xroom_ces ${isChrome ? 'chrome' : ''}`}>
      <XroomSXSWDesktop/> {/* Pass the language prop */}
    </main>
  );
};

export default XroomSXSW;
