import { Box } from '@chakra-ui/react';
import React from 'react';
import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { themedPalette } from '../../lib/styles/themes';

interface Props {}

/**
 * Bacgkround should be gray on following paths
 * - /
 * - /recent
 * - /lists
 */
function ConditionalBackground(props: Props) {
  const location = useLocation();

  const isGray = useMemo(
    () =>
      [{ path: '/', exact: true }, '/recent', '/lists'].some((path) =>
        matchPath(location.pathname,path.toString()),
      ),
    [location],
  );

  return isGray ? <Box bg='#F8F9FA'/> : <Box bg='#FFFFFF'/>;
}

export default ConditionalBackground;
