import React from 'react';
import styled from 'styled-components';
import { Box } from '@chakra-ui/react'; // Chakra UI Box 사용

// Styled component for video wrapper
// Styled component for video wrapper
const VideoWrapper = styled(Box)`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: block;
  position: relative;
  z-index: 1; // Lower z-index for video to ensure it appears below navbar

  // Mobile screens
  @media (max-width: 768px) {
    max-width: 90%;
  }

  // Tablet screens
  @media (min-width: 768px) and (max-width: 1024px) {
    max-width: 90%;
  }

  // Desktop screens
  @media (min-width: 1024px) {
    max-width: 1280px;
  }

  video {
    width: 100%;
    height: auto;
  }
`;

const Video: React.FC<{ language: string }> = ({ language }) => {
  return (
    <VideoWrapper>
      <video controls muted autoPlay loop playsInline>
        <source
          src="https://asset.soulx.co.kr/soulx-homepage/2024_CES_video3.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </VideoWrapper>
  );
};

export default Video;
