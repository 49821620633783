import * as React from 'react';
import useInput from '../../lib/hooks/useInput';
import { AuthMode } from '../../modules/core';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Stack,
  Text,
  Wrap,
  Link,
  useToast
} from '@chakra-ui/react';
import {
  socialLogin
} from '../../lib/api/auth';
import { SoulxLogo } from 'static/svg';
import AuthEmailSuccess from './AuthEmailSuccess';
import AuthLoginForm from './AuthLoginForm';
import {
  GoogleIcon,
} from '../XROOM/login/ProviderIcons';
// 구글 로그인
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

export interface AuthFormProps {
  mode: AuthMode;
  loading: boolean;
  onToggleMode: () => void;
  onSendAuthEmail: (email: string) => void;
  registered: boolean | null;
  currentPath: string;
  onClose: () => void;
}



const AuthForm: React.FC<AuthFormProps> = ({
  mode,
  onToggleMode,
  onSendAuthEmail,
  loading,
  registered,
  currentPath,
  onClose,
}) => {

  const toast = useToast();
  const [email, onChangeEmail] = useInput('');
  const onSubmit = (email: string) => {
    onSendAuthEmail(email);
  };

  type Todo = { id: number; text: string; done: boolean };

  const [open, setOpen] = React.useState(false);
  const onToggle = () => setOpen(!open);
  const [userData, setUserData] = React.useState<string>("");


  const auth = getAuth();

  // 파이어베이스 구글 로그인
  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
    .then((data) => {
      setUserData(data.user.uid);
      if(userData)login();
    })
    .catch((err) => {
      console.log(err);
      toast({
        title: '구글 로그인 실패',
        status: 'error', //success:성공
        // description: "We've created your account for you.",
        duration: 3000,//시간
        isClosable: true, //닫기
      });
    });
  }

  const login = async() => {
    const {data,statusCode} = await socialLogin(userData);
    if(statusCode===200) {
      localStorage.setItem("TOKEN",data);
    }
  }
  
  React.useEffect(()=>{
    if(userData!="")login();
  },[userData])

  return (
    <Flex justifyContent="space-between">
      <Wrap>
        <Stack>
          {registered !== null ? (
            <AuthEmailSuccess registered={registered} />
          ) : null}
          {open ? (
            <AuthLoginForm
              onToggle={onToggle}
              email={email}
              onChangeEmail={onChangeEmail}
              onSubmit={onSubmit}
              onToggleMode={onToggleMode}
              mode={mode}
              disabled={loading}
              onClose={onClose}
            />
          ) : (
            <ButtonGroup variant="outline" flexDirection={'column'}>
              <Text mb="1rem" opacity={0.8}>
                Choose how to login to your XROOM Account.
              </Text>
              <Stack>
                <Button
                  onClick={()=>{
                    onToggle()
                    mode==="REGISTER" && onToggleMode()
                  }
                  }
                  rounded={'5rem'}
                  key="Soulx"
                  w="full"
                  leftIcon={<SoulxLogo width="1.5rem" />}
                  backgroundColor="#1D4565"
                >
                  <Text color="#ffffff">Sign in with Soulx</Text>
                </Button>
                <Button
                  onClick={()=>{
                    // onToggle()
                    // mode==="REGISTER" && onToggleMode()
                    handleGoogleLogin();
                  }
                  }
                  rounded={'5rem'}
                  key="Google"
                  w="full"
                  leftIcon={<GoogleIcon width="2rem" />}
                  backgroundColor="#ffffff"
                >
                  <Text color="#000000">Sing in with Google</Text>
                </Button>
                
                {/* <AuthSocialButtonGroup currentPath={currentPath} /> */}

                <Text mt="1rem !important" color="white">
                  Don’t have a XROOM Account?
                  <Link href='/XROOM/register'>
                  <Button
                    variant="link"
                    style={{ color: '#00FE6F', marginLeft: '0.3rem' }}
                    onClick={()=>{
                      // onToggle()
                      mode==="LOGIN" && onToggleMode();

                    }}
                  >
                    Sign up
                  </Button>
                  </Link>
                  
                </Text>
              </Stack>
            </ButtonGroup>
          )}
        </Stack>
      </Wrap>
    </Flex>
  );
};

export default AuthForm;
