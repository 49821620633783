import { Box } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, useLocation } from 'react-router-dom';
import qs from 'qs';
import { shopList } from '../../lib/api/marketplace';
import { Heading } from '@chakra-ui/react';

export type MarketPlacePageProps = {};

interface TryInfo {
  try: string;
  result: string;
}

export type xroomUserInfo = {
  createdAt: string;
  email: string;
  id: number;
  macAddress: string;
  name: string;
  uid: string;
};

export type PostCardGridProps = {
  createdAt: string;
  description: string;
  fkCategoryId: string;
  fkUserId: 1;
  id: number;
  price: number;
  thumbnail: string;
  title: string;
  viewCount: number;
  xroomShopAssets: [];
  xroomUser: xroomUserInfo;
};

export type RowsType = {
  count: number;
  rows: any[];
};

function Marketplacepage(props: MarketPlacePageProps) {
  // 카드 목록 조회 axios
  const [listData, setListData] = useState<PostCardGridProps[]>([]);

  const cardList = async () => {
    const { data, statusCode } = await shopList();
    if (statusCode === 200) {
      const rows: any = data?.rows;
      setListData(rows);
    }
  };

  const location = useLocation();
  const query = useMemo(() => {
    const parsed = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }) as { q: string; username: string | undefined };
    return parsed;
  }, [location.search]);

  useEffect(() => {
    cardList();
  }, []);

  return (
    <>
      <Helmet>
        <title>마켓플레이스</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Box h="3xl" textAlign="center" mt="15rem">
        <Heading>준비중입니다.</Heading>

        {/* <Grid
          templateAreas={`"header header"
                        "nav main"
                        "nav footer"`}
          gridTemplateRows={'20% 1fr 10%'}
          h="100%"
          fontWeight="bold"
        >
          <GridItem alignSelf="center" area={'header'} w="100%">
            <LargeSearchInput initialKeyword={query.q} />
          </GridItem>
          <GridItem area={'main'} w="100%">
            <PostCardGrid
              postList={listData || []}
            />
          </GridItem>
          <GridItem area={'footer'}>
            <Divider />1 2 3 4 6 7 8 9
          </GridItem>
        </Grid> */}
        <Outlet />
      </Box>
    </>
  );
}

export default Marketplacepage;
