import {
  Box,
  Button,
  FormControl,
  HStack,
  IconButton,
  Input,
  Switch,
  Text,
} from '@chakra-ui/react';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { HiArrowLeft } from 'react-icons/hi';



interface AuthLoginFormProps {
  onChangeEmail: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onToggleMode: () => void;
  email: string;
  onSubmit: (email: string) => void;
  mode: 'REGISTER' | 'LOGIN';
  disabled: boolean;
  onToggle: (value: boolean) => void;
  onClose: () => void;
}

const AuthLoginForm: React.FC<AuthLoginFormProps> = ({
  onChangeEmail,
  email,
  onSubmit,
  onToggle,
  onToggleMode,
  mode,
  disabled,
  onClose,
}) => {
  // const isEmailError = email === '';
// function loginWithEamil(email, password) {
//   try {
//     await signInWithEmailAndPassword(authService, email, password);
//   } catch (e) {
//     return e.message.replace("Firebase: Error ", "");
//   }
// }
const navigate = useNavigate();
console.log(mode)

  return (  
    <Box
      className="login-form"
      display="inline-flex"
      flexDirection="column"
      textAlign="left"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(email);
      }}
    >
      <IconButton
        onClick={() => onToggle(false)}
        children={<HiArrowLeft />}
        aria-label={'HiArrowLeft'}
        variant="unstyled"
        w="fit-content"
        position="absolute"
        left="1em"
        top="1em"
        color="white"
      />
      
      <Text as="b" display="block" fontSize="2xl" color="white">
        {mode === 'REGISTER' ? 'Register' : 'Login'}
      </Text>
      <form onSubmit={() => console.log('gdsgsag')}>
        <FormControl mt="1rem">
          <Input
            id="email"
            type="email"
            onChange={onChangeEmail}
            value={email}
            autoComplete="current-email"
            tabIndex={2}
            placeholder="Email adress"
            disabled={disabled}
            color='white'
          />
        </FormControl>
        <HStack mt="1rem" justify="space-between">
          <Switch fontSize={12} alignSelf="center" color="white">
            Remember me
          </Switch>
          <Button
            tabIndex={4}
            disabled={disabled}
            variant="primary"
            bgColor={'#2A56CA'}
            rounded={'2rem'}
            onClick={() => onSubmit(email)}

          >
            {mode === 'REGISTER' ? 'register' : 'Login'}
          </Button>
        </HStack>
      </form>
      <HStack justify="center">
        <Button variant="link" onClick={()=>{
          onToggleMode();
          if(mode==="LOGIN"){
            navigate("/XROOM/register");
          }
          }}>
          <Text variant="link" color={'#00FE6F'} fontSize={12}>
            {mode === 'REGISTER' ? 'Log in?' : 'Sign Up?'}
          </Text>
        </Button>
      </HStack>
    </Box>
  );
};

export default AuthLoginForm;
