import React, { useState } from 'react';
import { Box, Button, Heading } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export type CESContainerProps = {};
function CESContainer(props: CESContainerProps) {
  // change please
  window.location.href = 'https://soulx.co.kr/';

  return (
    <Box h="3xl" textAlign="center" mt="15rem">
      <Heading>Loading ...</Heading>
      {/* <a href="https://naver.com">
      <Button colorScheme="blue" mt="3rem">
        Cespage
      </Button>
    </a> */}
    </Box>
  );
}

export default CESContainer;
