import apiClient from './apiClient';
// import snakeCaseKeys from 'snakecase-keys';

// export type shopResponse = {
//   xroomShopId: number,
//   xroomShopTitle: string,
//   xroomShopUploadeDate: string,
//   xroomShopPrice: number,
//   xroomShopThumbnail: null,
//   xroomShopDescription: string,
//   xroomShopSeller: number,
//   xroomShopCategory: string,
//   xroomShopViews: number
//   }

export type shopResponse = {
  data: shopResponseRows;
  statusCode: number;
};
export type shopResponseRows = {
  count: number;
  rows: any[];
};

export const shopList = async () => {
  const res = await apiClient.get<shopResponse>('/shop');
  return res.data;
};

export {};
