import { Stack } from '@chakra-ui/react';
import * as React from 'react';

interface OpaqueLayerProps {
  visible: boolean;
}

const { useState, useEffect, useRef } = React;

const OpaqueLayer: React.FC<OpaqueLayerProps> = ({ visible }) => {
  const [animate, setAnimate] = useState(false);
  const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);
  const mounted = useRef(false);
  const [closed, setClosed] = useState(true);

  // activates animation & hides and unhides scrollbar
  useEffect(() => {
    // scrollbar
    document.body.style.overflowY = visible ? 'hidden' : 'initial';

    // animate
    if (!mounted.current) {
      mounted.current = true;
    } else {
      setAnimate(true);
      timeoutId.current = setTimeout(() => {
        setAnimate(false);
        if (!visible) {
          setClosed(true);
        }
      }, 250);
    }

    if (visible) {
      setClosed(false);
    }

    return () => {
      if (!timeoutId.current) return;
      clearTimeout(timeoutId.current);
    };
  }, [visible]);

  useEffect(() => {
    return () => {
      document.body.style.overflowY = 'initial';
    };
  }, []);

  if (!animate && !visible && closed) return null;

  return <Stack className="OpaqueLayer" />;
};

export default OpaqueLayer;
