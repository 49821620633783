import React from 'react';
import styled from 'styled-components';

const VideoContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin: 324px 0px;
  @media (max-width: 1280px) {
  margin: 75px 20px;
  }

  @media (max-width: 768px) {
  margin: 75px 20px;
  }
  /* 비디오 너비 설정 */
  video {
    width: 100%; /* 컨테이너의 너비에 맞게 비율을 유지 */
    max-width: 1280px; /* 최대 너비를 1280px로 설정 */
    height: auto; /* 비율을 유지 */
  }



  @media (max-width: 480px) {
    video {
      width: calc(100% - 40px); /* 모바일에서 좌우 20px 간격 유지 */
      max-width: none; /* 최대 너비 제한 제거 */
    }
  }
`;

const Video: React.FC = () => {
  return (
    <VideoContainer>
      <video controls muted autoPlay loop playsInline preload="metadata">
        <source
          src="https://asset.soulx.co.kr/soulx-homepage/2024_CES_video3.mp4"
          type="video/mp4"
        />
        {/* Fallback content for accessibility */}
        Your browser does not support the video tag.
      </video>
    </VideoContainer>
  );
};

export default Video;
