import React, { useEffect } from 'react';
import ErrorScreenTemplate from './ErrorScreenTemplate';
import { useLocation, useNavigate } from 'react-router-dom';
import useNotFound from '../../lib/hooks/useNotFound';
import { Helmet } from 'react-helmet-async';
import { usePrevious } from 'react-use';

export type NotFoundErrorProps = {};

function NotFoundError(props: NotFoundErrorProps) {
  const Navigate = useNavigate();
  const { reset } = useNotFound();
  const location = useLocation();

  const prevPathname = usePrevious(location.pathname);

  useEffect(() => {
    if (prevPathname && prevPathname !== location.pathname) {
      reset();
    }
  }, [location.pathname, prevPathname, reset]);

  return (
    <>
      <Helmet>
        <title>404 - XROOM</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <ErrorScreenTemplate
        message="아무것도 없네요!"
        buttonText="홈으로"
        onButtonClick={() => {
          Navigate('/');
          reset();
        }}
      />
    </>
  );
}

export default NotFoundError;
