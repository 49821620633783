import { Box, Image, Text } from '@chakra-ui/react';

export type IImgCard = {
  imgName: string;
  desc?: string; // Optional, but could be made required based on usage
  isHorizontal?: boolean;
};

const ImgCard: React.FC<IImgCard> = ({ imgName, desc = '', isHorizontal = false }) => {
  return (
    <Box
      className="img_card"
      w={{ base: '100%', md: '482px', lg: isHorizontal ? '620px' : '400px' }} 
      maxW="100%" 
      borderRadius={20}
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      mx="auto"
      height={{ base: isHorizontal ? 'auto' : '318px', md: isHorizontal ? '300px' : '250px', lg: isHorizontal ? '320px' : '300px' }} 
      padding={isHorizontal ? '0' : '10px'} 
      boxShadow="0 4px 20px rgba(0, 0, 0, 0.1)"
      transition="transform 0.3s ease" // Add a transition effect for hover
      _hover={{ transform: 'scale(1.02)' }} // Slight scaling effect on hover
    >
      <Image
        src={`images/xroom_ces/${imgName}`}
        alt={desc ? `${desc} image` : 'Image'} // More descriptive alt text
        width="100%"
        height="100%"

        borderRadius={20}
      />
      {isHorizontal && desc && ( // Render desc only if it exists
        <Text
          position="absolute"
          bottom={22}
         
          fontWeight={700}
          color="white"
          aria-live="polite" // Announce description for screen readers if necessary
        >
          {desc}
        </Text>
      )}
    </Box>
  );
};

export default ImgCard;
