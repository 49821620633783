import React from 'react';
import styled from 'styled-components';
import { Image } from '@chakra-ui/react';
// Headerbar styled component
const Headerbar = styled.nav`
  position: fixed;
  width: 100%;
  height: 80px;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1A0C32;
  z-index: 1000;

  @media (max-width: 768px) {
    height: 60px; 
  }
`;

// HeaderBox styled component with responsive margins
const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  flex: 1;
  padding: 0 20px; /* Replace large margins with padding */
  max-width: 1200px; /* Set max width for content */
  flex-wrap: nowrap;

  @media (max-width: 1024px) {
    padding: 0 40px;
  }

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

// Styled component for text
// Styled component for text with better handling for overflow
const StyledText = styled.p`
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis (...) for overflowed text */
  color: white;
  font-size: 20px; /* Default font size */
  line-height: 25px;
  letter-spacing: 0.3px;
  text-align: center;

  
  @media (max-width: 1024px) {
    font-size: 18px; /* Reduce font size for tablets */
    line-height: 22px;
    max-width: 400px; /* Limit width for tablets */
  }

  @media (max-width: 768px) {
    font-size: 16px; /* Further reduce font size for mobile */
    line-height: 20px;
    max-width: 250px; /* Further limit width for mobile */
    white-space: nowrap; /* Prevent wrapping on mobile */
    text-overflow: ellipsis; /* Add ellipsis if text overflows */
  }
`;


// Styled component for responsive image
const StyledImage = styled(Image)`
  width: 89px;
  height: 79px;

  @media (max-width: 1024px) {
    width: 75px;
    height: 65px;
  }

  @media (max-width: 768px) {
    width: 50px; /* Smaller image for mobile */
    height: 45px;
  }
`;
// Header component
const Header: React.FC<{ language: string }> = ({ language }) => {
  const headerText =
    language === 'EN'
      ? 'The 2024 Edison Awards Winner for ‘Innovative Entertainment & Design Technology’'
      : '소울엑스, 미국 최고 발명상 ‘Edison Awards 2024’ 혁신적인 엔터테인먼트 & 디자인 기술 부문 수상';

  return (
    <Headerbar id="mainHeader">
      <HeaderBox id="HeaderBox">
        <StyledImage
          src="/images/Header/EdisonAwards.png"
          alt="Edison Awards"
        />
        <StyledText>
          {headerText}
        </StyledText>
      </HeaderBox>
    </Headerbar>
  );
};

export default Header;
