import React from 'react';
import ErrorScreenTemplate from './ErrorScreenTemplate';
import { useNavigate } from 'react-router-dom';
import logo from '../../../public/images/LogoFacebook.svg';
export type CrashErrorScreenProps = {
  onResolve: () => void;
};

function CrashErrorScreen({ onResolve }: CrashErrorScreenProps) {
  const history = useNavigate();
  return (
    <ErrorScreenTemplate
      message="엇! 오류가 발생했습니다."
      buttonText="홈으로"
      onButtonClick={() => {
        history('/');
        onResolve();
      }}
    />
  );
}

export default CrashErrorScreen;
